@import '../abstracts/theme.less';

.icon-help {
  color: var(--primary);

  &:hover,
  &:focus {
    color: var(--primary-hover);
    border-color: var(--primary-hover);
  }
}

.icon-small {
  width: 16px;
  height: 16px;
}

.icon-medium {
  width: 24px;
  height: 24px;
}

.icon-large {
  width: 32px;
  height: 32px;
}

.icon-xlarge {
  width: 64px;
  height: 64px;
}
